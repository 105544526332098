import React, { useEffect, useState } from "react";
import { Container, Row, Spinner } from "reactstrap";
import "../../../assets/css/kellner.css";

import KellnerReadyView from "./kellnerCooking/KellnerReadyView";

import KellnerActiveView from "./kellnerActive/KellnerActiveView";
import { useDispatch, useSelector } from "react-redux";

import { getAllSessions } from "../../../store/actions/tableSessionsAction";
import { socket } from "../../../services/services.sockets";
import PayWaiter from "../../../components/Modals/payWaiterModal";
import ServiceNotification from "../../../components/Modals/servicenotify";

const Kellner = () => {
  const [activeTab, setActiveTab] = useState("active");
  const dispatch = useDispatch();
  const [paywaiterModal, setPaywaiterModal] = useState(false);
  const sessions = useSelector((state) => state?.tableSession?.sessions);
  // const [priorityOrders, setPriorityOrders] = useState("");
  const [filterSession, setFilterSession] = useState([]);
  // const [otherOrders, setOtherOrders] = useState("");
  // const [selecTable, setSelectTable] = useState();
  // const loading = useSelector((state) => state?.tableSession?.loading);
  const getallTablesLoader = useSelector(
    (state) => state?.tableSession?.getallTablesLoader
  );
  const loaderServe = useSelector(
    (state) => state.tableSession?.theServeLoader
  );
  const [notifyModal, setNotifyModal] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const tableChange = useSelector((state) => state?.tableSession?.tableChange);
  const orderStore = useSelector((state) => state?.orders?.orders);
  const mergeResStore = useSelector((state) => state?.tableSession?.mergeTable);
  const blId = useSelector((state) => state.business.blId);

  const theServeLoader = useSelector(
    (state) => state?.tableSession.theServeLoader
  );
  useEffect(() => {
    socket.on("problem_received", async (data) => {
      if (data?.payWaiter) {
        setPaywaiterModal(true);
        setNotifyMsg(data);
      }
    });
  }, [socket]);
  useEffect(() => {
    if (blId) {
      dispatch(getAllSessions(blId));
    }
  }, [blId, orderStore]);

  useEffect(() => {
    if (tableChange?.success || mergeResStore?.success) {
      dispatch(getAllSessions(blId));
    }
  }, [tableChange, mergeResStore, theServeLoader, orderStore]);

  useEffect(() => {
    //Socket Receving Service Notification
    socket.on("notification_saved", async (data) => {
      setNotifyMsg(data);
      if (data?.payWaiter) {
        setNotifyModal(true);
      }
    });
  }, [socket]);

  // useEffect(() => {
  //   sortSessions();
  // }, [loaderServe]);

  useEffect(() => {
    sortSessions();
  }, [sessions, orderStore, loaderServe]);

  const sortSessions = () => {
    const filterByOrder = [];
    orderStore?.forEach((item) => {
      if (item?.salesEntries?.length > 0) {
        const filtered = sessions?.find((x) => x?._id === item?.sessionId);
        if (filtered) {
          filterByOrder.push(filtered);
        }
      }
    });
    setFilterSession([...filterByOrder]);
  };
  if (getallTablesLoader) {
    return (
      <>
        <Container>
          <Row className="p-2 mt-5 d-flex justify-content-center align-items-center">
            <Spinner />
          </Row>
        </Container>
      </>
    );
  }
  return (
    <Container>
      <Row className="p-2 my-5 d-flex justify-content-center align-items-center">
        <div className="d-flex justify-content-center my-2">
          <button
            className={
              activeTab === "active"
                ? " active-btn kbtn mx-2"
                : "ready-btn kbtn mx-2"
            }
            onClick={() => {
              setActiveTab("active");
            }}
          >
            ACTIVE
          </button>
          <button
            className={
              activeTab === "ready"
                ? " active-btn kbtn mx-2"
                : "ready-btn kbtn mx-2"
            }
            onClick={() => {
              setActiveTab("ready");
            }}
          >
            ALL
          </button>
        </div>

        {activeTab === "active" ? (
          <KellnerActiveView
            activeSessions={sessions?.length > 0 ? filterSession : []}
          />
        ) : (
          <KellnerReadyView
            cookedOrders={sessions?.length > 0 ? filterSession : []}
            activeTab={activeTab}
          />
        )}
      </Row>
      <PayWaiter
        paywaiterModal={paywaiterModal}
        setPaywaiterModal={setPaywaiterModal}
        notifyMsg={notifyMsg}
        blId={blId}
      />
      <ServiceNotification
        notifyModal={notifyModal}
        setNotifyModal={setNotifyModal}
        notifyMsg={notifyMsg}
        blId={blId}
      />
    </Container>
  );
};

export default Kellner;
