import {
  FETCH_PARKING_STATIONS_SUCCESS,
  FETCH_PARKING_STATIONS_LOADING,
  FETCH_PARKING_STATIONS_FAIL,
  FREE_PARKING_STATION_LOADING,
  FREE_PARKING_STATION_SUCCESS,
} from "../Type/parkingTypes";

const initialState = {
  parkingStations: null,
  loading: false,
  parkingStaionError: null,
  stationBusyLoading: false,
};

export default function parkingReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_PARKING_STATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PARKING_STATIONS_FAIL:
      return {
        ...state,
        loading: false,
        parkingStaionError: payload,
      };
    case FETCH_PARKING_STATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        parkingStations: payload,
      };
    case FREE_PARKING_STATION_LOADING:
      return {
        ...state,
        stationBusyLoading: true,
      };
    case FREE_PARKING_STATION_SUCCESS:
      return {
        ...state,
        stationBusyLoading: false,
      };
    default:
      return state;
  }
}
