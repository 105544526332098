// import './App.css';
import React, { useState, useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "./services/services.sockets";
//Views Import
import Login from "./views/Login";
import ManagerDashboard from "./views/Manager/ManagerDashboard";
import EmployeeDashboard from "./views/Employee/EmployeeDashboard";
import HomeView from "./views/Employee/HomeView";
import OrderList from "./views/Manager/OrderList";
import Recipe from "./views/Employee/Recipe";
import RecipeList from "./views/Employee/RecipeList";
import Kellner from "./views/Manager/kellnerView/Kellner";
import NotificationServices from "./components/Dashboard/notificationServices/notificationServices";
import ServiceNotification from "./components/Modals/servicenotify";
import SingleOrder from "./views/SingleOrder";
//Bell Import
import notification from "./notification.mp3";
//Actions

import Protected from "./protectedRoutes";
import { Home } from "./components/Dashboard/Home/Home";
import { firebaseOrderListner } from "./utils/firebaseListener";

function App() {
  const dispatch = useDispatch();
  const [order, setOrder] = useState(false);
  const [notifyModal, setNotifyModal] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const blId = useSelector((state) => state?.business?.blId);
  const orders = useSelector((state) => state?.orders?.orders);
  const audioEle = useRef();
  // Notification FUnction
  const notify = () => {
    // var audio = new Audio(notification);
    // if (audio !== undefined) {
    // audio.muted = false;
    // audio.play();
    audioEle?.current?.play();
    // }
  };

  //Sockets Receiving Packets
  useEffect(() => {
    socket.on("problem_received", async (data) => {
      setNotifyMsg(data);
      if (data?.service) {
        setNotifyModal(true);
      }
    });
    //Socket Receving Service Notification
    socket.on("notification_saved", async (data) => {
      setNotifyMsg(data);
      if (data?.service) {
        setNotifyModal(true);
      }
    });
  }, [socket]);

  if (order === true) {
    setTimeout(() => {
      setOrder(false);
    }, 5000);
  }
  useEffect(() => {
    if (blId) {
      firebaseOrderListner(blId, orders, dispatch, notify);
    }
  }, [blId]);

  return (
    <div className="App">
      <div className="d-none ">
        <audio ref={audioEle} controls autoPlay src={notification}></audio>
      </div>
      <Routes>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route
          path="/Admin/dashboard"
          element={
            <Protected isSignedIn={blId}>
              <ManagerDashboard />
            </Protected>
          }
        >
          <Route
            path="/Admin/dashboard/:MenuName"
            element={
              <Protected isSignedIn={blId}>
                <ManagerDashboard />
              </Protected>
            }
          ></Route>
          <Route
            index
            element={
              <Protected isSignedIn={blId}>
                <Home />
              </Protected>
            }
          />
          {/* <Route
            path="orders"
            element={
              <Protected isSignedIn={blId}>
                <HomeView />
              </Protected>
            }
          /> */}
          <Route path="order-list" element={<OrderList />}></Route>
          <Route
            path="tables"
            element={
              <Protected isSignedIn={blId}>
                <Kellner />
              </Protected>
            }
          ></Route>
          <Route path="single-order" element={<SingleOrder />}></Route>
          <Route
            path="service-notification"
            element={<NotificationServices />}
          ></Route>
        </Route>
        <Route path="/Employee/dashboard" element={<EmployeeDashboard />}>
          <Route
            path="/Employee/dashboard/:MenuName"
            element={<EmployeeDashboard />}
          ></Route>
          <Route index element={<HomeView />} />
          {/* <Route path="orders" element={<HomeView />} /> */}
          <Route path="order-list" element={<Recipe />}></Route>
          <Route
            path="tables"
            element={
              <Protected isSignedIn={blId}>
                <Kellner />
              </Protected>
            }
          ></Route>
          {/* <Route path='recipe' element={<Recipe />}></Route> */}
          <Route path="single-order" element={<SingleOrder />}></Route>
          <Route path="recipe-list" element={<RecipeList />}></Route>
          <Route path="recipe-list/:id" element={<RecipeList />}></Route>
        </Route>
      </Routes>

      {/* <audio ref={audioPlayer} src={notification} /> */}
      <ServiceNotification
        notifyModal={notifyModal}
        setNotifyModal={setNotifyModal}
        notifyMsg={notifyMsg}
        blId={blId}
      />
    </div>
  );
}

export default App;
