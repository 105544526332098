import { useState } from "react";
import {
  STATION_OPEN_MODAL,
  MANAGE_ITEM_OPEN_MODAL,
  STATION_CLOSE_MODAL,
} from "../../../../store/types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Navbar,
  NavbarToggler,
  NavbarText,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Button,
  ButtonGroup,
} from "reactstrap";
import "../../../../assets/css/bottomNavbar.css";
import { logout } from "../../../../store/actions/authAction";
import {
  orderPriorityAction,
  orderCancelAction,
  removeItemAction,
  dishPrioirtyAction,
} from "../../../../store/actions/orderAction";
import MergeModal from "../../../Modals/merge";
import ChangeTableNameModal from "../../../Modals/changeTableName";
import { socket } from "../../../../services/services.sockets";
import BottomNavGrayBtn from "./BottomNavGrayBtn";
import StationStatusModal from "../../../Modals/StationStatusModal";

const BottomNav = ({ active, setActive }) => {
  // const Items = ["PASTA", "PINSA", "INSALATA", "DRINKS"]
  const [hoverIcon, setHoverIcon] = useState(-1);
  const location = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const idStore = useSelector((state) => state?.info);
  const { tableNo } = useSelector((state) => state?.modal);
  const infoId = useSelector((state) => state?.info?.oId);
  const modalOpenStation = useSelector((st) => st?.info?.id?.stationName);
  const [openMergeModal, setOpenMergeModal] = useState(false);
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [stationStatus, setStationStatus] = useState(false);

  const logoutFunc = () => {
    dispatch(logout());
    dispatch({
      type: "LOG_OUT",
    });
    navigate("/", { replace: true });
  };

  const priortyChangeFunc = () => {
    if (location.pathname.includes("employee")) {
      if (idStore?.id) {
        dispatch(
          dishPrioirtyAction({
            order_id: idStore?.id?.Id,
            // sku: idStore?.id?.stationName?.itemSku,
            item_id: idStore?.id?.stationName?._id,
            priority:
              idStore?.id?.stationName?.priority === "urgent"
                ? "normal"
                : "urgent",
          })
        ).then(() => {
          socket.emit("processing", {});
        });
      } else {
        alert("you have not chossed any value.");
      }
    } else {
      if (idStore?.oId?.length === 0) {
        dispatch(
          dishPrioirtyAction({
            order_id: idStore?.id?.Id,
            // sku: idStore?.id?.stationName?.itemSku,
            item_id: idStore?.id?.stationName?._id,
            priority:
              idStore?.id?.stationName?.priority === "urgent"
                ? "normal"
                : "urgent",
          })
        ).then(() => {
          socket.emit("processing", {});
        });
      } else if (idStore?.id?.length === 0) {
        if (infoId == null || infoId.length === 0) {
          alert("you have not selected any value");
        } else {
          if (infoId !== null && infoId.priority === "normal") {
            dispatch(
              orderPriorityAction({
                order_id: infoId?.Id,
                priority: "urgent",
              })
            ).then(() => {
              socket.emit("processing", {});
            });
          } else if (infoId !== null && infoId.priority === "urgent") {
            dispatch(
              orderPriorityAction({
                order_id: infoId?.Id,
                priority: "normal",
              })
            ).then(() => {
              socket.emit("processing", {});
            });
          }
        }
      } else if (idStore?.oId.length === 0 && idStore?.id.length === 0) {
        alert("You haven't choose any value.");
      }
    }
    // dispatch({ type: CLEAR_ORDER_ID });
  };

  const mangeFunc = () => {
    if (modalOpenStation?.length > 0 || modalOpenStation !== undefined) {
      dispatch({
        type: MANAGE_ITEM_OPEN_MODAL,
      });
    } else {
      alert("Please first choose a dish.");
    }
  };

  const cancelOrderFunc = () => {
    if (idStore?.oId.length === 0) {
      if (idStore?.id?.Id) {
        dispatch(
          removeItemAction({
            orderId: idStore?.id?.Id,
            saleEntryID: idStore?.id?.stationName?._id,
          })
        );
        dispatch({ type: STATION_CLOSE_MODAL });
      }
    } else if (
      idStore?.id.length === 0 &&
      location.pathname.includes("admin")
    ) {
      if (infoId !== null) {
        dispatch(
          orderCancelAction({
            order_id: infoId?.Id,
          })
        );
      }
    } else if (idStore?.oId < 0 && idStore?.id.length < 0) {
      console.log("You haven't choose any value. ");
    }
  };

  function assignBtnFunc() {
    if (modalOpenStation?.length > 0 || modalOpenStation !== undefined) {
      dispatch({ type: STATION_OPEN_MODAL });
    } else {
      alert("Please first choose a dish.");
    }
  }

  return (
    <>
      <Navbar
        // color="dark"
        dark
        expand="sm"
        fixed="bottom"
        className=" bottom-footer pt-0 pb-0"
      >
        <div>
          {/* <Row> */}
          <span className="bottom-small-content bgGray text-center d-block mb-3">
            <b>Main</b>
          </span>
          {/* </Row> */}

          <div className="d-flex main-box">
            <BottomNavGrayBtn
              text={"STATS"}
              logo={`/img/icons/STATS_${
                hoverIcon === "STATS" ? "GRAY" : "WHITE"
              }.png`}
              setHoverIcon={setHoverIcon}
            />
            <BottomNavGrayBtn
              text={"PRINT"}
              logo={`/img/icons/PRINT_${
                hoverIcon === "PRINT" ? "GRAY" : "WHITE"
              }.png`}
              setHoverIcon={setHoverIcon}
            />
            <BottomNavGrayBtn
              text={"Notification"}
              notext={true}
              logo={`/img/icons/bellicon-${
                hoverIcon === "Notification" ? "gray" : "white"
              }.png`}
              setHoverIcon={setHoverIcon}
              path={"service-notification"}
            />
          </div>
        </div>
        <NavbarToggler className="me-4" onClick={function noRefCheck() {}} />
        <Collapse navbar>
          <Nav className="ms-auto" navbar>
            <NavItem className="pt-0 mb-2">
              <NavLink className="pt-0">
                <span className="order-function text-center d-block mb-2 text-white">
                  <b>ORDER FUNCTION</b>
                </span>

                <ButtonGroup
                  size="lg"
                  className="d-flex align-items-start btn-group-bottom"
                >
                  {location.pathname.includes("employee") ? (
                    ""
                  ) : (
                    <Button
                      className={
                        active === "assign"
                          ? "botm-center-btn-active"
                          : hoverIcon === "assign"
                          ? "botm-center-btn-active"
                          : "botm-center-btn"
                      }
                      // color="dark"
                      onClick={() => assignBtnFunc()}
                      onMouseEnter={() => {
                        setHoverIcon("assign");
                      }}
                      onMouseLeave={() => {
                        setHoverIcon(-1);
                      }}
                    >
                      <img
                        src={`/img/icons/ASSIGN_${
                          hoverIcon === "assign" ? "GREEN" : "WHITE"
                        }.png`}
                        className="img-fluid"
                        alt="assign"
                      />
                      <h6>ASSIGN</h6>
                    </Button>
                  )}
                  <Button
                    className={
                      active === "cancel"
                        ? "botm-center-btn-active"
                        : hoverIcon === "cancel"
                        ? "botm-center-btn-active"
                        : "botm-center-btn"
                    }
                    color="dark"
                    onClick={() => {
                      cancelOrderFunc();
                    }}
                    onMouseEnter={() => {
                      setHoverIcon("cancel");
                    }}
                    onMouseLeave={() => {
                      setHoverIcon(-1);
                    }}
                  >
                    <img
                      src={`/img/icons/DENY_${
                        hoverIcon === "cancel" ? "GREEN" : "WHITE"
                      }.png`}
                      className="img-fluid"
                      alt="assign"
                      height={6}
                      width={40}
                    />
                    <h6>CANCEL</h6>
                  </Button>
                  <Button
                    className={
                      active === "recall"
                        ? "botm-center-btn-active"
                        : hoverIcon === "recall"
                        ? "botm-center-btn-active"
                        : "botm-center-btn"
                    }
                    color="dark"
                    onClick={() => {
                      setActive("recall");
                      location.pathname.includes("employee")
                        ? navigate("/employee/dashboard/single-order", {
                            state: { order: "cooked" },
                          })
                        : navigate("/admin/dashboard/single-order", {
                            state: { order: "cooked" },
                          });
                    }}
                    onMouseEnter={() => {
                      setHoverIcon("recall");
                    }}
                    onMouseLeave={() => {
                      setHoverIcon(-1);
                    }}
                  >
                    <img
                      src={`/img/icons/RECALL_${
                        hoverIcon === "recall" || active === "recall"
                          ? "GREEN"
                          : "WHITE"
                      }.png`}
                      className="img-fluid"
                      alt="assign"
                      height={6}
                      width={40}
                    />
                    <h6>RECALL</h6>
                  </Button>
                  {location.pathname.includes("employee") ? (
                    ""
                  ) : (
                    <Button
                      className={
                        active === 6
                          ? "botm-center-btn-active"
                          : hoverIcon === 6
                          ? "botm-center-btn-active"
                          : "botm-center-btn"
                      }
                      color="dark"
                      onClick={() => mangeFunc()}
                      onMouseEnter={() => {
                        setHoverIcon(6);
                      }}
                      onMouseLeave={() => {
                        setHoverIcon(-1);
                      }}
                    >
                      <img
                        src={`/img/icons/SET_${
                          hoverIcon === 6 ? "GREEN" : "WHITE"
                        }.png`}
                        className="img-fluid"
                        alt="assign"
                        height={6}
                        width={40}
                      />
                      <h6>MODIFY</h6>
                    </Button>
                  )}
                  <div
                    className="priority-btn"
                    onClick={() => {
                      priortyChangeFunc();
                    }}
                    onMouseEnter={() => {
                      setHoverIcon(7);
                    }}
                    onMouseLeave={() => {
                      setHoverIcon(-1);
                    }}
                  >
                    <img
                      src={`/img/icons/ALERT_${
                        hoverIcon === 7 ? "GREEN" : "WHITE"
                      }.png`}
                      className="img-fluid mt-2"
                      alt="assign"
                      height={6}
                      width={40}
                    />
                    <h6>PRIORITY</h6>
                  </div>
                </ButtonGroup>
              </NavLink>
            </NavItem>
            {location.pathname.includes("employee") ? (
              ""
            ) : (
              <NavItem className="ml-3 pt-0 ">
                <NavLink className="pt-0  ">
                  <Row className="ms-1">
                    <span className="table-function text-center mb-2 text-white">
                      <b>TABLE FUNCTION</b>
                    </span>
                  </Row>
                  <ButtonGroup
                    size="lg"
                    className="d-flex align-items-start btn-group-bottom"
                  >
                    <Button
                      className={
                        active === 8
                          ? "botm-center-btn-active"
                          : hoverIcon === 8
                          ? "botm-center-btn-active"
                          : "botm-center-btn"
                      }
                      color="dark"
                      onMouseEnter={() => {
                        setHoverIcon(8);
                      }}
                      onMouseLeave={() => {
                        setHoverIcon(-1);
                      }}
                    >
                      <img
                        src={`/img/icons/MERGE_${
                          hoverIcon === 8 ? "GREEN" : "WHITE"
                        }.png`}
                        className="img-fluid"
                        alt="assign"
                        height={6}
                        width={40}
                        onClick={() => {
                          tableNo.firstTableName && setOpenMergeModal(true);
                        }}
                      />
                      <h6>MERGE</h6>
                    </Button>
                    <Button
                      // className="botm-center-btn"
                      className={
                        active === 9
                          ? "botm-center-btn-active"
                          : hoverIcon === 9
                          ? "botm-center-btn-active"
                          : "botm-center-btn"
                      }
                      color="dark"
                      onMouseEnter={() => {
                        setHoverIcon(9);
                      }}
                      onMouseLeave={() => {
                        setHoverIcon(-1);
                      }}
                      onClick={() => {
                        tableNo.firstTableName && setOpenChangeModal(true);
                      }}
                    >
                      <img
                        src={`/img/icons/CHANGE_${
                          hoverIcon === 9 ? "GREEN" : "WHITE"
                        }.png`}
                        className="img-fluid"
                        alt="assign"
                        height={6}
                        width={40}
                      />
                      <h6>CHANGE</h6>
                    </Button>

                    <Button
                      className={
                        active === "list"
                          ? "botm-center-btn-active"
                          : hoverIcon === "list"
                          ? "botm-center-btn-active"
                          : "botm-center-btn"
                      }
                      color="dark"
                      onMouseEnter={() => {
                        setHoverIcon("list");
                      }}
                      onMouseLeave={() => {
                        setHoverIcon(-1);
                      }}
                      onClick={() => {
                        setActive("list");
                        if (location.pathname.includes("employee")) {
                          navigate("/employee/dashboard/tables");
                        } else {
                          navigate("/admin/dashboard/tables");
                        }
                      }}
                    >
                      <img
                        src={
                          active === "list"
                            ? "/img/icons/LIST_GREEN.png"
                            : `/img/icons/LIST_${
                                hoverIcon === "list" ? "GREEN" : "WHITE"
                              }.png`
                        }
                        className="img-cover"
                        alt="assign"
                        height={40}
                        width={40}
                      />
                      <h6>LIST</h6>
                    </Button>
                  </ButtonGroup>
                </NavLink>
              </NavItem>
            )}
          </Nav>

          <NavbarText className="ms-auto pt-0 pb-1">
            <div>
              {/* <Row> */}
              <span className="bottom-small-content bgGray text-center d-block mb-3 ">
                <b>SET</b>
              </span>
              {/* </Row> */}

              <div className="d-flex main-box">
                <div
                  className="btn-Gray"
                  onMouseEnter={() => {
                    setHoverIcon("status");
                  }}
                  onMouseLeave={() => {
                    setHoverIcon(-1);
                  }}
                >
                  <div
                    className="gray-icon py-2 d-flex justify-content-center align-items-center"
                    onClick={() => {
                      setStationStatus(true);
                      setActive("status");
                    }}
                  >
                    <img
                      src={
                        active === "status"
                          ? "/img/icons/status_WHITE.png"
                          : `/img/icons/status_${
                              hoverIcon === "list" ? "GRAY" : "WHITE"
                            }.png`
                      }
                      className="img-fluid"
                      alt="assign"
                      height={6}
                      width={40}
                    />
                  </div>
                  <h6>STATUS</h6>
                </div>
                <div
                  className="btn-Gray"
                  onMouseEnter={() => {
                    setHoverIcon(11);
                  }}
                  onMouseLeave={() => {
                    setHoverIcon(-1);
                  }}
                >
                  <div className="gray-icon py-2 d-flex justify-content-center align-items-center">
                    <img
                      src={`/img/icons/LANG_${
                        hoverIcon === 11 ? "GRAY" : "WHITE"
                      }.png`}
                      className="img-fluid"
                      alt="assign"
                      height={6}
                      width={40}
                    />
                  </div>
                  <h6>LANGUAGE</h6>
                </div>
                <div
                  className="btn-Gray"
                  onMouseEnter={() => {
                    setHoverIcon(12);
                  }}
                  onMouseLeave={() => {
                    setHoverIcon(-1);
                  }}
                  onClick={() => logoutFunc()}
                >
                  <div className="gray-icon py-2 d-flex justify-content-center align-items-center">
                    <img
                      src={`/img/icons/LOGOUT_${
                        hoverIcon === 12 ? "GRAY" : "WHITE"
                      }.png`}
                      className="img-fluid"
                      alt="assign"
                      height={6}
                      width={40}
                    />
                  </div>

                  <div>
                    <h6>LOGOUT</h6>
                  </div>
                </div>
              </div>
            </div>
          </NavbarText>
        </Collapse>
      </Navbar>
      <MergeModal
        openMergeModal={openMergeModal}
        setOpenMergeModal={setOpenMergeModal}
      />
      <ChangeTableNameModal
        openChangeModal={openChangeModal}
        setOpenChangeModal={setOpenChangeModal}
      />
      <StationStatusModal
        setStationStatus={setStationStatus}
        stationStatus={stationStatus}
      />
    </>
  );
};

export default BottomNav;
