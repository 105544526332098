import React from "react";
import { useDispatch } from "react-redux";
import { ORDER_ID } from "../../../../store/types";

const LiveCardHeader = ({ item }) => {
  // const sessions = useSelector((state) => state?.tableSession?.sessions);
  // let selectedsession = sessions?.find(
  //   (_item) => item.sessionId === _item?._id
  // );
  const dispatch = useDispatch();
  const OrderSelectFunc = (orderID) => {
    dispatch({
      type: ORDER_ID,
      payload: {
        Id: orderID,
        priority: item?.priority,
      },
    });
  };
  return (
    <>
      <div className="orderTop">
        <div
          className="ordTp bgorder-darkblue"
          onClick={() => {
            if (location.pathname.includes("admin")) {
              OrderSelectFunc(item?._id);
            }
          }}
        >
          <span>
            <img src="/img/icons/BILL_WHITE.png" alt="logo" />
          </span>
          <span className="oT_no">
            {item?.pagerNumber
              ? item.pagerNumber
              : item?._id?.substr(item?._id?.length - 4)}
          </span>
        </div>
        <div className="ordTp bgorder-blue">
          <span>
            <img src="/img/icons/TABLE_WHITE.png" alt="logo" />
          </span>
          <span className="oT_no">{item?.tableNumber}</span>
        </div>
        {item?.order_type === "served" ? (
          <div className="ordTp bgorder-yellow d-flex justify-content-around">
            <span>
              <img src="/img/icons/INSTORE_WHITE.png" alt="logo" />
            </span>
            {item?.station ? (
              <span className="oT_no">{item?.station}</span>
            ) : (
              <img src="/img/icons/queue.png" alt="logo" />
            )}
          </div>
        ) : (
          <div className="ordTp bgorder-pink ">
            <span>
              <img src="/img/icons/INSTORE_WHITE.png" alt="logo" />
            </span>
            <span className="oT_no">T</span>
          </div>
        )}
      </div>
    </>
  );
};

export default LiveCardHeader;
