import React from "react";

const LiveDishCourse = ({ item, cardData, index }) => {
  //find Course items
  const checkCourse = (item, array, index) => {
    for (let i = 0; i < index; i++) {
      if (item?.course == array[i]?.course) {
        return true;
      }
    }
  };
  return (
    <div className="course">
      <span>
        {checkCourse(item, cardData, index) ? null : (
          <div>
            <p
              style={{
                padding: "8px",
                fontWeight: "bold",
                margin: 0,
              }}
            >
              {item?.course?.toUpperCase()}
            </p>
          </div>
        )}
      </span>
    </div>
  );
};
export default LiveDishCourse;
