import React from "react";
import SerialHandler from "../../../../serialHandler";
const serialHandler = new SerialHandler();
const PagerCardBtn = ({ pagerNumber }) => {
  const connectPager = () => {
    try {
      console.log("init");
      serialHandler.init();
      console.log("connect");
      return serialHandler.connect().then(() => {
        if (serialHandler.isConnected()) {
          return serialHandler.sendPagerCallCommand(pagerNumber).then(
            () => {
              console.log({
                status: "ok",
              });
            },
            (error) => {
              console.log({
                status: "Serial call failed! " + error,
              });
              throw error;
            }
          );
        }
      });
    } catch (error) {
      alert("Error in connection" + error);
    }
  };
  const pagerHandler = () => {
    try {
      if (serialHandler.isConnected()) {
        return serialHandler.sendPagerCallCommand(pagerNumber).then(
          () => {
            console.log({
              status: "ok",
            });
          },
          (error) => {
            console.log({
              status: "Serial call failed! " + error,
            });
            throw error;
          }
        );
      } else {
        connectPager();
      }
    } catch (error) {
      alert("Error in connection" + error);
    }
  };

  const pagerSocketEmit = () => {
    pagerHandler().catch((error) => {
      console.error(error);
    });
  };
  return (
    <div
      className="pagerTag d-flex justify-content-center align-items-center"
      onClick={pagerSocketEmit}
    >
      <img src="/img/icons/pager.png" height={38} alt="dishLogo" />
    </div>
  );
};

export default PagerCardBtn;
