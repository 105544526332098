import React, { useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { Col, Row } from "reactstrap";
import { employeeSettings } from "../../../assets/sliderSetting";
import MultiOrders from "./multiorders";
import { socket } from "../../../services/services.sockets";
import PayWaiter from "../../Modals/payWaiterModal";
import { useState } from "react";
import { useLocation } from "react-router-dom";
const Dishes = ({
  data,
  setUpdateOrder,
  updateOrder,
  setListenDataChange,
  listenDataChange,
}) => {
  const location = useLocation();
  const [paywaiterModal, setPaywaiterModal] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [filterdArray, setFilterArray] = useState([]);
  const SingleLoading = useSelector((state) => state?.orders?.singleLoading);
  const cookingUpdateLoader = useSelector(
    (state) => state?.orders?.cookingUpdateLoader
  );
  var selected_menu = location?.state?.menu;
  useLayoutEffect(() => {
    let barItems = data?.filter((orders) => {
      return orders.salesEntries?.some((dish) => {
        return (
          dish.station === selected_menu &&
          (dish?.status === "cooking" || dish?.status === "pending")
        );
      });
    });
    let filterdarray = barItems?.sort((a, b) =>
      a?.priority == "urgent" || b?.priority == "urgent" ? -1 : 1
    );
    setFilterArray(filterdarray && [...filterdarray]);
  }, [data]);

  useEffect(() => {
    socket.on("notification_saved", async (data) => {
      if (data?.payWaiter) {
        setPaywaiterModal(true);
        setNotifyMsg(data);
      }
    });
    //   socket.on("order_received", () => {
    //     console.log("the data order >>");
    //   });
  }, []);
  return (
    <>
      {filterdArray?.length === 0 ? (
        <div className=" mt-5  text-muted d-flex align-items-center justify-content-center">
          <p>Sorry this station does not have a relevant order or dish.</p>
        </div>
      ) : (
        <Row className="mb-5">
          <Col md="12" lg="12" sm="12" xs="12">
            <Slider {...employeeSettings} className="mx-5">
              {filterdArray?.map((order, index) => {
                if (filterdArray?.length) {
                  return (
                    <div key={index}>
                      {" "}
                      <MultiOrders
                        order={order}
                        setUpdateOrder={(obj) => {
                          setUpdateOrder(obj);
                        }}
                        SingleLoading={
                          SingleLoading === true ||
                          (cookingUpdateLoader &&
                            updateOrder?._id === order?._id)
                        }
                        updateOrder={updateOrder}
                        selected_menu={selected_menu}
                        setListenDataChange={setListenDataChange}
                        listenDataChange={listenDataChange}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={index}>
                      <h4 className="text-mute">
                        There is no more items to cook...
                      </h4>
                    </div>
                  );
                }
              })}
            </Slider>
          </Col>
        </Row>
      )}
      <PayWaiter
        paywaiterModal={paywaiterModal}
        setPaywaiterModal={setPaywaiterModal}
        notifyMsg={notifyMsg}
      />
    </>
  );
};

export default Dishes;
