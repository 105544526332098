// import React, { useEffect, useState } from "react";i

const LiveCardFooter = ({ item }) => {
  // const [cookingStatus, SetCookingStatus] = useState("");
  const current = new Date();
  const orderCreation = new Date(
    item?.latestItem ? item?.latestItem : item?.createdAt
  );
  const cookingStarted = item?.cooking_started
    ? new Date(item?.cooking_started)
    : false;

  function msConverter(diff) {
    if (diff < 0) {
      //in some cases cooking time out of sync so adjust time
      diff += 50000; //30sec time adjustment
    }
    var msec = Math.abs(diff);
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;
    if (hh < 10) {
      hh = "0" + hh;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (ss < 10) {
      ss = "0" + ss;
    }
    return hh + ":" + mm + ":" + ss;
  }
  let latestCreatedAt = new Date("0000-01-01T00:00:00.000");
  for (let i = 0; i < item.salesEntries.length; i++) {
    const createdAt = new Date(item.salesEntries[i]?.createdAt);
    if (createdAt > latestCreatedAt) {
      latestCreatedAt = createdAt;
    }
  }

  return (
    <div className="orderFooter ">
      <div className="ordTp-double bgOrder-Gray">
        <div className="d-flex">
          <span className="">
            <img src="/img/icons/TIMER_WHITE.png" alt="logo" />
          </span>
          <span className="bgof-green time-txt">
            {msConverter(current - orderCreation)}
          </span>
        </div>
        <div className="line"></div>
        <div className="d-flex mt-1">
          <span>
            <img src="/img/icons/TIMER_WHITE.png" alt="logo" />
          </span>
          <span className="bgof-green time-txt">
            {msConverter(current - latestCreatedAt) || "00:00:00"}
          </span>
        </div>
      </div>
      <div className={`ordTp ${item?.cookingColor}`}>
        <span>
          <img src="/img/icons/READY_WHITE.png" alt="logo" />
        </span>
        {/* <span className="bgof-green text-white"> */}
        <span className="time-txt">
          {cookingStarted === false
            ? // <CountDown />
              "00:00:00"
            : msConverter(current - cookingStarted)}
        </span>
      </div>
    </div>
  );
};

export default LiveCardFooter;
