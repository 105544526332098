export default class SerialHandler {
  serial;
  static pagerPayloadStartByte = 1; // 0x01 / stx
  static pagerPayloadEndByte = 3; // 0x03 / etx

  port;
  reader;
  writer;
  encoder = new TextEncoder();
  decoder = new TextDecoder();
  onConnect;
  onDisconnect;

  usbProductId;
  usbVendorId;

  connected = false;
  reconnectTimeout;

  /**
   * Takes the number of the pager to call and encodes it to a correct pager call payload.
   * @param pagerNumber - The number of the pager (0-9999).
   * @returns The pager payload as Uint8Array.
   */
  static createPagerPayload(pagerNumber) {
    /*
     * Full payload example
     * ASCII: stx 1234 etx
     * HEX: 0x0 1 0x31 0x32 0x33 0x34 0x03
     */
    const array = new Uint8Array(6);
    array[0] = SerialHandler.pagerPayloadStartByte; // Number as hex
    array[5] = SerialHandler.pagerPayloadEndByte; // Number as hex
    const str = pagerNumber.toString().padStart(4, "0");
    for (let i = 0; i < 4; i++) {
      array[i + 1] = str[i].charCodeAt(0); // Character as hex, e.g. '1' = 0x31
    }
    return array;
  }

  /**
   * Triggers the menu where the user will pick a device (it requires an user interaction to be triggered).
   * Opens the port selected by the user in the UI using a defined `baudRate`; this example uses a hard-coded value of 9600.
   * After opening the port, a `writer` and a `reader` are set; they will be used by the `write` and `read` methods respectively.
   */
  init(onConnect, onDisconnect) {
    this.onConnect = onConnect;
    this.onDisconnect = onDisconnect;
    if (
      navigator.userAgent.toLowerCase().indexOf("android") > -1 ||
      !("serial" in navigator)
    ) {
      // eslint-disable-next-line no-undef
      this.serial = exports.serial; // Serial polyfill
    } else {
      this.serial = navigator.serial;
    }
    navigator.serial.addEventListener("disconnect", async (e) => {
      console.log(`Device disconnected: ${e}`);
      this.connected = false;
      this.onDisconnect();
    });
  }

  /**
   * Establish connection to choosen serial interface
   * @returns {Promise<void>}
   */
  async connect() {
    try {
      this.serial.requestPort().then(async (port) => {
        this.port = port;
        const { usbProductId, usbVendorId } = port.getInfo();
        this.usbProductId = usbProductId;
        this.usbVendorId = usbVendorId;
        port.open({ baudRate: 9600 }).then(() => {
          this.writer = port.writable.getWriter();
          this.reader = port.readable.getReader();
          this.connected = true;
          this.onConnect();
        });
      });
    } catch (err) {
      console.error("There was an error opening the serial port:", err);
      throw err;
    }
  }

  /**
   * Takes a string of data, encodes it and then writes it using the `writer` attached to the serial port.
   * @param data - A string of data that will be sent to the Serial port.
   * @returns An empty promise after the message has been written.
   */
  write(data) {
    const dataArrayBuffer = this.encoder.encode(data);
    return this.writer.write(dataArrayBuffer);
  }

  /**
   * Takes the number of the pager to call and sends a corresponding serial payload to the main unit.
   * @param pagerNumber - The number of the pager (0-9999).
   * @returns The pager payload as Uint8Array.
   */
  sendPagerCallCommand(pagerNumber) {
    console.log(`Sending byte array: ${pagerNumber}`);
    return this.writer.write(SerialHandler.createPagerPayload(pagerNumber));
  }

  /**
   * Gets data from the `reader`, decodes it and returns it inside a promise.
   * @returns A promise containing either the message from the `reader` or an error.
   */
  async read() {
    try {
      const readerData = await this.reader.read();
      return this.decoder.decode(readerData.value);
    } catch (err) {
      const errorMessage = `error reading data: ${err}`;
      console.error(errorMessage);
      return errorMessage;
    }
  }

  isConnected() {
    return this.connected;
  }
}
