import { useEffect, useState } from "react";

import {
  Navbar,
  NavbarToggler,
  NavbarText,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import "../../../assets/css/Topnavbar.css";
import { useLocation } from "react-router-dom";
import { getAllStations } from "../../../store/actions/stationAction";
import { useDispatch, useSelector } from "react-redux";
import TopNavStationBtn from "./TopNavStationsBtn";

const TopNav = ({ active, setActive }) => {
  const [hoverIcon, setHoverIcon] = useState(-1);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getAllStations());
    if (
      location.pathname === "/admin/dashboard" ||
      location.pathname === "/employee/dashboard"
    ) {
      setActive("live");
    }
  }, []);

  const userStations = useSelector(
    (state) => state?.authUser?.user?.data?.stations
  );
  let userStationsObj = {};
  userStations.forEach((element) => {
    if (!userStationsObj[element]) {
      userStationsObj[element] = element;
    }
  });

  return (
    <>
      <Navbar
        // dark
        expand="md"
        fixed="top"
        className="header-top pt-0 pb-0"
      >
        <div className="logo-div d-flex">
          <div className="logo-img mx-3">
            <img
              src="/img/operate-logo2.png"
              alt="Logo"
              className="img-responsive me-3 w-100 h-100"
              style={{ objectFit: "contain" }}
            />
          </div>
          {/* liveButton Starts */}
          <TopNavStationBtn
            item={""}
            index={"Live"}
            state={"all"} // navigate with keywords for data filter
            active={active}
            setActive={setActive}
            logo={"/img/icons/LIVE_BLACK.png"}
            logoActive={"/img/icons/LIVE_WHITE.png"}
          />
          {/* liveButton Fin */}
          {/* Recipe BTN for employee Starts */}
          {location.pathname.includes("employee") ? (
            <TopNavStationBtn
              item={"RECIPIES"}
              index={"recipes"}
              path={"recipe-list"}
              active={active}
              setActive={setActive}
            />
          ) : null}
          {/* Recipe BTN for employee fin */}
        </div>
        {/* Station BTNs for employee start */}
        <NavbarToggler onClick={function noRefCheck() {}} />
        <Collapse navbar>
          <Nav className="ms-auto" navbar>
            <NavItem>
              <NavLink>
                <div className="logo-div d-flex align-items-center">
                  {userStations?.map((item, i) => (
                    <TopNavStationBtn
                      item={item}
                      key={i}
                      index={i}
                      state={item} // navigate with keywords for data filter
                      path={"single-order"}
                      active={active}
                      setActive={setActive}
                    />
                  ))}
                </div>
              </NavLink>
            </NavItem>
          </Nav>
          {/* Station BTNs for employee fin */}
          <NavbarText className="ms-auto ">
            <div className="logo-div d-flex">
              <TopNavStationBtn
                item={"Kellner"}
                index={"kel"}
                path={"tables"}
                active={active}
                setActive={setActive}
              />

              <TopNavStationBtn
                item={"orderList"}
                index={"all"}
                path={"order-list"}
                state={"all"}
                active={active}
                setActive={setActive}
                setHoverIcon={setHoverIcon}
                hoverIcon={hoverIcon}
                logo={
                  location.pathname.includes("employee")
                    ? "/img/icons/PROGR_BLACK.png"
                    : "/img/icons/ORDERLIST_BLACK.png"
                }
                logoActive={
                  location.pathname.includes("employee")
                    ? "/img/icons/PROGR_WHITE.png"
                    : "/img/icons/ORDERLIST_WHITE.png"
                }
              />
            </div>
          </NavbarText>
        </Collapse>
      </Navbar>
    </>
  );
};

export default TopNav;
