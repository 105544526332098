import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { getOrdersBySession } from "../../../../store/actions/orderAction";
import KellnerReadyCard from "./KellnerReadyCard";

const KellnerCooked = ({
  cookedOrders,
  setAlertMsg,
  setWarn,
  setSelectedSession,
  activeTab,
}) => {
  const dispatch = useDispatch();
  const [cookedOrdersParent, setCookedOrdersParent] = useState([]);
  const allSession = useSelector((state) => state.tableSession.sessions);

  useEffect(() => {
    if (cookedOrders?.length && allSession?.length) {
      syncParentithChilds();
    }
  }, [allSession, cookedOrders]);

  const syncParentithChilds = () => {
    let allOrders = [];
    cookedOrders.forEach((_itm) => {
      if (_itm.isParent) {
        allOrders.push(_itm);
      } else {
        let parentOrder = allSession.find((item) => item._id === _itm.parentId);
        if (parentOrder?._id) {
          allOrders.push(parentOrder);
        }
      }
    });
    // Create a set to store unique order IDs
    let uniqueOrderIds = new Set();

    // Create a new array to store orders without duplicates
    let filteredOrders = [];

    // Iterate over the orders and check for duplicates
    allOrders.forEach((order) => {
      let orderId = order?._id;
      if (!uniqueOrderIds.has(orderId)) {
        uniqueOrderIds.add(orderId);
        filteredOrders.push(order);
      }
    });
    setCookedOrdersParent(filteredOrders);
  };

  return (
    <Row>
      {cookedOrdersParent
        // ?.filter(
        //   (item) =>
        //     item?.serve_status == true && item?.order_status === "cooked"
        // )
        .sort((a, b) => a.table_id - b.table_id)
        ?.map((cookeOrder, index) => {
          return (
            <Col sm="6" xs="6" md="2" lg="2" key={index}>
              <KellnerReadyCard
                cookOrder={cookeOrder}
                setAlertMsg={setAlertMsg}
                setWarn={setWarn}
                setSelectedSession={() => {
                  setSelectedSession(cookeOrder);
                  dispatch(getOrdersBySession(cookeOrder));
                }}
                activeTab={activeTab}
              />
            </Col>
          );
        })}
    </Row>
  );
};

export default KellnerCooked;
