import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../../../../services/services.sockets";
import { cookingProcessUpdate } from "../../../../store/actions/orderAction";
import {
  CLEAR_ORDER_ID,
  GET_ID,
  ORDER_DISH_COOK,
} from "../../../../store/types";

const LiveBodyCard = ({
  orderItem,
  item,
  setDataLoading,
  // setOrderObj,
  // setStationSelectModal,
}) => {
  const dispatch = useDispatch();
  const idStore = useSelector((store) => store?.info?.id);
  const sessions = useSelector((state) => state?.tableSession?.sessions);

  //==============Start cooking process=============
  const cookingFunc = (dish) => {
    setDataLoading(true);
    SetOrderSelectForCook(item, dish);
    dispatch(
      cookingProcessUpdate({
        order_id: orderItem._id,
        sales_entry_id: dish?._id,
        dish_status: item?.status === "pending" ? "cooking" : "cooked",
        sessionId: orderItem?.sessionId,
        sessions,
      })
    ).then(() => {
      setDataLoading(false);
      socket.emit("processing", {});
    });
  };
  const SetOrderSelectForCook = (item, dish) => {
    dispatch({
      type: ORDER_DISH_COOK,
      payload: {
        orderid: item,
        saleEntry: dish,
      },
    });
  };
  //Set Dish for Actions
  const StationItemChange = (id, item) => {
    dispatch({
      type: GET_ID,
      payload: { Id: id, stationName: item },
    });
    dispatch({ type: CLEAR_ORDER_ID });
  };
  return (
    <>
      <div
        className={
          item?.priority === "urgent"
            ? `bgitemheader_hotpink d-flex justify-content-between`
            : "bgitemheader_hotpink_gray d-flex justify-content-between"
        }
      >
        <div className="itemheader">
          {/* <b>{item?.quantity}x </b> */}
          <b
            className={
              // props?.saleEntrySelected === item?._id && !props?.clearSelection
              idStore === item?._id
                ? "itemheader_name_selected "
                : "itemheader_name"
            }
            onClick={() => {
              // SetStationItem(item);
              // props?.setSaleEntrySelected(item?._id);
              StationItemChange(orderItem._id, item);
              // props?.setUpdateOrder();
            }}
          >
            {item && item._id ? item?.itemName?.toUpperCase() : "N/A"}
          </b>
        </div>
        <div
          className={
            item?.status === "cooked" && item?.payment_status === "paid"
              ? "oi_pow bgplayPaid"
              : item?.status === "cooked"
              ? "oi_pow bgplaygreen"
              : item?.status === "cooking"
              ? "oi_pow bgorder-yellow"
              : item?.payment_status === "half_paid" ||
                item?.payment_status === "paid"
              ? "oi_pow bgplayWhite"
              : "oi_pow bgOrder-light-Gray"
          }
        >
          {location.pathname.includes("employee") ? (
            <div
              className="playbtn bgplay"
              // className={`playbtn bgplay${props.playBgColor}`}
              onClick={() => {
                cookingFunc(item);
              }}
            >
              <img src="/img/icons/play.png" height={30} alt="playicon" />
            </div>
          ) : (
            <div
              onClick={() => {
                cookingFunc(item);
              }}
            >
              {item?.station?.toUpperCase()}
            </div>
          )}
        </div>
      </div>
      <div className={item?.subLineItems?.length > 0 ? "itembody" : ""}>
        {item?.subLineItems?.map((sl, index) => {
          return (
            <div key={index}>
              {(sl.type === "standard" ||
                sl.type === "Standard" ||
                sl.type === "normal") &&
              sl.isSingleOnly === true ? (
                <div className="titleitem mt-2">
                  <span>
                    <img src="/img/icons/MIDARROWRIGHT_BLACK.png" alt="logo" />
                  </span>
                  <span>{sl?.itemName}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
        <div className="two-card-sec mt-3 ">
          {item?.redItems?.length > 0 ? (
            <div className="block-card bg-pink py-3">
              <span>
                {" "}
                <img src="/img/icons/MINUS_BLACK.png" alt="minus" height="12" />
              </span>

              {item?.redItems?.map((gi, index) => {
                return (
                  <div className="f-16" key={index}>
                    <span>{gi?.itemName}</span>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
          {item?.greenItems?.length > 0 ? (
            <div className="block-card bg-lightGreen py-3 mt-2">
              <span>
                {" "}
                <img src="/img/icons/PLUS_BLACK.png" alt="minus" height="12" />
              </span>
              {item?.greenItems?.map((gr, index) => {
                return (
                  <div className="ps-3 f-16" key={index}>
                    <span>{gr?.itemName}</span>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default LiveBodyCard;
