import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Form, Input, Col, Spinner } from "reactstrap";
import NumberBtn from "./NumberBtn";
import { login } from "../../store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import SerialHandler from "../../serialHandler";
const serialHandler = new SerialHandler();
const LoginCard = ({ setOpenBidModal }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.authUser?.loading);

  const [pin, setPIN] = useState("");

  const navigate = useNavigate();
  const connectToPager = () => {
    try {
      console.log("init");
      serialHandler.init();
      console.log("connect");
      return serialHandler.connect().then(() => {});
    } catch (error) {
      alert("Error in connection" + error);
    }
  };
  const submit = async (e) => {
    e.preventDefault();
    let resp = await dispatch(login(pin));
    connectToPager();
    // if (
    //   resp.type != "FAIL_ACCESS" &&
    //   resp.payload?.data?.user_type == 'Manager'

    // ) {
    //   navigate("/admin/dashboard",{
    //     state: { menu: "all" },
    //   });
    // } else if (
    //   resp.type != "FAIL_ACCESS" &&
    //   resp.payload?.data?.user_type == 'Employee'
    // ) {
    //   navigate("/employee/dashboard",{
    //     state: { menu: "all" },
    //   });
    // }
    // console.log('resData',resData)
    if (resp.payload?.data?.user_type == "Manager") {
      navigate("/admin/dashboard");
    } else if (resp.payload?.data?.user_type == "Employee") {
      navigate("/employee/dashboard");
    }
  };

  useEffect(() => {
    setOpenBidModal(true);
  }, []);
  const onClick = (button) => {
    // if(button === "="){
    //     this.calculate()
    // }

    // else if(button === "C"){
    //     this.reset()
    // }
    // else if(button === "CE"){
    //     this.backspace()
    // }

    // else {
    // this.setState({
    //     result: this.state.result + button
    // })
    // }
    setPIN(pin + button);
  };
  function bacckspace(e) {
    e.preventDefault();
    setPIN(pin.slice(0, -1));
  }
  if (loading || !localStorage.getItem("blId")) {
    return (
      <>
        <Col lg="5" md="7" className="mt-5">
          <div className="d-flex position-absolute top-50 start-50">
            <Spinner />
          </div>
        </Col>
      </>
    );
  }

  return (
    <>
      <Col lg="5" md="7" className="mt-5">
        <div className=" bg-secondary shadow login-card  p-3 w-75">
          <Form role="form" onSubmit={(e) => submit(e)}>
            <div className="mb-3 d-felx justify-content-center">
              <Input
                placeholder="Enter number"
                type="text"
                className="input-style text-white"
                value={pin}
                style={{ fontSize: "24px" }}
                onChange={(e) => {
                  setPIN(e.target.value);
                }}
                required
              />
            </div>
            <div className="d-flex flex-row justify-content-center">
              <NumberBtn number={1} onClick={onClick} />
              <NumberBtn number={2} onClick={onClick} />
              <NumberBtn number={3} onClick={onClick} />
              <NumberBtn number={4} onClick={onClick} />
            </div>
            <div className="d-flex flex-row justify-content-center">
              <NumberBtn number={5} onClick={onClick} />
              <NumberBtn number={6} onClick={onClick} />
              <NumberBtn number={7} onClick={onClick} />
              <NumberBtn number={8} onClick={onClick} />
            </div>
            <div className="d-flex flex-row justify-content-center">
              <NumberBtn symbol={"#"} onClick={onClick} />
              <NumberBtn number={9} onClick={onClick} />
              <NumberBtn number={"0"} onClick={onClick} />

              <NumberBtn symbol={"*"} onClick={onClick} />
            </div>
            <div className="d-flex w-100">
              <button
                className="btn-gray m-2"
                onClick={(e) => {
                  bacckspace(e);
                }}
              >
                <img
                  src="/img/backspace.png"
                  alt="icon "
                  className="logo-btn"
                />
              </button>
              <button className="btn-yellow-login m-2">
                <img src="img/right.png" alt="icon " className="logo-btn" />
              </button>
            </div>
          </Form>
        </div>
      </Col>
    </>
  );
};

export default LoginCard;
