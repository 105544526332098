import React, { useEffect } from "react";
import ModalInterface from "../ModalInterface";
import { Badge, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_CHANGE_MERGE_MODAL } from "../../store/types";
import {
  UpdataTableSession,
  getAllTableAction,
} from "../../store/actions/tableSessionsAction";

const ChangeTableNameModal = ({ openChangeModal, setOpenChangeModal }) => {
  const { tableNo } = useSelector((state) => state?.modal);
  const { allTables } = useSelector((state) => state?.tableSession);
  const blId = useSelector((state) => state.business.blId);

  const dispatch = useDispatch();
  const CloseClear = () => {
    setOpenChangeModal(false);
    dispatch({
      type: CLEAR_CHANGE_MERGE_MODAL,
    });
  };
  const tableChange = (tablenumber) => {
    console.log(tableNo);
    dispatch(
      UpdataTableSession({
        id: tableNo.firstTableName.id,
        table_id: tablenumber.number,
      })
    );
    CloseClear();
  };
  useEffect(() => {
    if (blId) {
      dispatch(getAllTableAction(blId));
    }
  }, [blId]);
  return (
    <>
      <ModalInterface
        display={openChangeModal}
        ceneter={true}
        bsSize={"lg"}
        content={
          <Row className="p-0">
            <Col md="12" sm="12" xs="12" lg="12">
              <div className="d-flex justify-content-between align-items-start pb-5">
                <div className="heading-seesion"> CHANGE SESSION NAME</div>
                <div className="cross-button">
                  <img
                    src="/img/icons/DENY_WHITE.png"
                    width={30}
                    height={30}
                    alt=""
                    onClick={() => {
                      CloseClear();
                    }}
                  />
                </div>
              </div>
              <div className="session-group  p-3">
                {allTables.map((table, index) => {
                  return (
                    <div key={index}>
                      {table?.number == tableNo.firstTableName?.number ? (
                        <Badge
                          color=""
                          pill
                          className="badge-bt muted p-2 mx-2"
                        >
                          <span className="text-muted">
                            {tableNo.firstTableName?.number}
                          </span>
                        </Badge>
                      ) : (
                        <Badge
                          color="info"
                          className="p-2 m-2"
                          pill
                          onClick={() => {
                            tableChange(table);
                          }}
                        >
                          <span>Table No {table?.number}</span>
                        </Badge>
                      )}
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        }
        closeEvent={() => {
          setOpenChangeModal(false);
        }}
      />
    </>
  );
};

export default ChangeTableNameModal;
